import styled, { css } from "styled-components"
import { typography, mq } from "styles"
import { NoStyleButton } from "components/UI/Button/NoStyleButton"

type $IsInsideModal = { $isInsideModal: boolean }

export const insideModalStyles = css`
  padding: 80px 30px 0;
  background-color: ${({ theme }) => theme.dark.canvas?.primary.opacityHex};
  ${mq.minWidth("md")} {
    border: 1px solid ${({ theme }) => theme.dark?.ui?.divider?.opacityHex};
  }
`

export const outsideModalStyles = css`
  padding: 24px;
  border: 1px solid ${({ theme }) => theme.dark.ui?.divider?.opacityHex};
  background-color: ${({ theme }) => theme.dark.canvas?.primary?.opacityHex};
  cursor: pointer;
`

export const ReviewInner = styled(NoStyleButton)<$IsInsideModal>`
  color: ${({ theme }) => theme.dark.text?.primary?.opacityHex};
  ${({ $isInsideModal }) =>
    $isInsideModal ? insideModalStyles : outsideModalStyles};
  position: relative;
  text-align: left;
  height: 100%;
  width: 100%;
  overflow: auto;
  ${mq.minWidth("md")} {
    padding: 32px;
  }
`

export const AuthorContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 8px;
  margin: 13px 0 2px;
`

export const Author = styled.span`
  ${typography.bodySmall};
  font-weight: bold;
  display: block;
`

export const VerifiedBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--reviews-verified-fill);
  width: 16px;
  height: 16px;
  border-radius: 50%;
  svg {
    width: 8px;
    display: block;
  }
`

export const Date = styled.span`
  ${typography.bodySmall};
  color: ${({ theme }) => theme.dark.text?.secondary?.opacityHex};
`

export const Meta = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
`

export const Verified = styled.span`
  ${typography.caption};
  color: var(--reviews-verified-text);
  align-items: flex-end;
`

export const Title = styled.h4`
  ${typography.body};
  color: var(--reviews-title-text);
  margin: 13px 0 12px;
  ${mq.minWidth("md")} {
    margin: 18px 0 16px;
  }
`

export const Content = styled.div`
  ${typography.bodySmall};
`

export const Cta = styled(NoStyleButton)`
  border-bottom: 1px solid
    ${({ theme }) => theme.dark.text?.primary?.opacityHex};
  color: ${({ theme }) => theme.dark.text?.primary?.opacityHex};
  transition: border-bottom 0.2s ease;
  ${typography.bodySmall};
  margin-top: 20px;
  ${mq.minWidth("md")} {
    margin-top: 24px;
  }
  &:hover {
    border-bottom: 1px solid transparent;
  }
`
