import React, { useState } from "react"
import "react-input-range/lib/css/index.css"
import { ReviewCarousel } from "./ReviewCarousel"
import { Maybe, SanityProduct, YotpoProductEdge } from "typings/graphql"
import { Grid } from "components/UI/Grid/Grid"
import { ReviewSelector } from "./ReviewSelector"
import { ModeType } from "typings/modules"
import { PortableText } from "components/UI/PortableText/PortableText"
import { Container, Content } from "./ReviewList.styles"

type Props = {
  mode: ModeType
  header: Record<string, unknown>
  bgColor: string
  products: SanityProduct[]
}

export type ActiveProduct = {
  productId: string
  title: string
  handle: string
}

export type ReviewedProducts = Maybe<YotpoProductEdge[]>

const initProduct = {
  productId: "",
  title: "",
  handle: "",
}

export const ReviewList = (props: Props) => {
  const [activeProduct, setActiveProduct] = useState<ActiveProduct>(initProduct)
  const { header, mode, products, bgColor } = props
  return (
    <Container $bgColor={bgColor}>
      {header && (
        <Content>
          <Grid base="1 [10] 1" md="2 [12] 2" lg="4 [18] 4">
            <PortableText blocks={header} $mode={mode} />
          </Grid>
        </Content>
      )}
      <ReviewSelector
        activeProduct={activeProduct}
        setActiveProduct={setActiveProduct}
        products={products}
      />
      <ReviewCarousel activeProduct={activeProduct} $mode={mode} />
    </Container>
  )
}
