import React, { useState, useEffect, useRef } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Query, YotpoProduct } from "typings/graphql"
import Slider from "react-slick"
import PrevSvg from "assets/svg/arrow-left.svg"
import NextSvg from "assets/svg/arrow-right.svg"
import { ReviewItem } from "./ReviewItem"
import { ActiveProduct } from "./ReviewList"
import { useProductPathPrefix } from "hooks/useProductPathPrefix"
import { Grid } from "components/UI/Grid/Grid"
import { Button } from "components/UI/Button/Button"
import {
  Container,
  Content,
  Meta,
  Title,
  Pipe,
  StyledLink,
  Reviews,
  PrevButton,
  NextButton,
  StyledSlider,
  Review,
  Cta,
} from "./ReviewCarousel.styles"
import { ReviewStars } from "components/YotpoReviewList/ReviewStars"
import { $Mode } from "typings/modules"
import { useCommonMediaQuery } from "hooks/useMediaQuery"

type Props = { activeProduct: ActiveProduct } & $Mode

const query = graphql`
  query {
    allYotpoProduct {
      nodes {
        ...YotpoProduct
      }
    }
  }
`

export const ReviewCarousel = ({ activeProduct, $mode }: Props) => {
  const [reviews, setReviews] = useState<YotpoProduct[]>([])
  const { allYotpoProduct } = useStaticQuery<Query>(query)
  const [slider, setSlider] = useState<Slider | null>(null)
  const [infinite, setInfinte] = useState<boolean>(false)
  const sliderRef = useRef<Slider | null>(null)
  const productPathPrefix = useProductPathPrefix()
  useEffect(() => {
    const activeReviews = allYotpoProduct?.nodes.filter(
      node => node.productId === activeProduct.productId
    )
    setReviews(activeReviews)
  }, [activeProduct])
  useEffect(() => {
    if (sliderRef.current) {
      setSlider(sliderRef.current)
    }
  }, [sliderRef.current])
  const reviewsNode = reviews[0]
  const reviewsList = reviewsNode?.reviews || []
  const averageScore = reviewsNode?.bottomline?.averageScore?.toFixed(2) ?? 0
  const totalReviews = reviewsNode?.bottomline?.totalReview ?? 0
  const handleNextSlide = () => (slider ? slider.slickNext() : null)
  const handlePrevSlide = () => (slider ? slider.slickPrev() : null)
  const productUrl = `${productPathPrefix}${activeProduct.handle}`
  const { isTablet, isDesktop } = useCommonMediaQuery()
  useEffect(() => {
    if (isDesktop) {
      setInfinte(reviewsList.length > 4)
    } else if (isTablet) {
      setInfinte(reviewsList.length > 2)
    } else {
      setInfinte(reviewsList.length > 1)
    }
  }, [reviewsList, isDesktop, isTablet])
  return (
    <Container>
      <Content>
        <Grid base="1 [10] 1" md="2 [12] 2" lg="4 [18] 4">
          <Title $mode={$mode}>{activeProduct.title} Reviews</Title>
          <ReviewStars
            starCount={averageScore as number}
            reviewCount={totalReviews}
          />
          <Meta>
            <span>{averageScore} Stars</span>
            <Pipe>|</Pipe>
            <span>{totalReviews} Reviews</span>
          </Meta>
          <StyledLink to={productUrl}>
            Shop the {activeProduct?.title}
          </StyledLink>
        </Grid>
        <Reviews>
          {isDesktop && infinite && (
            <PrevButton
              aria-label="Show Previous Reviews"
              onClick={handlePrevSlide}
            >
              <PrevSvg role="img" aria-label="Previous Icon" />
            </PrevButton>
          )}
          <StyledSlider
            ref={sliderRef}
            infinite={infinite}
            slidesToScroll={1}
            slidesToShow={4}
            dots={false}
            speed={500}
            draggable={false}
            responsive={[
              {
                breakpoint: 1024,
                settings: {
                  infinite,
                  slidesToShow: 2,
                  centerMode: true,
                },
              },
              {
                breakpoint: 768,
                settings: {
                  infinite,
                  slidesToShow: 1,
                  centerMode: true,
                },
              },
            ]}
          >
            {reviewsList?.map((review, i) => (
              <Review key={`review-node-${i}`}>
                <ReviewItem review={review} reviewCount={reviewsList.length} />
              </Review>
            ))}
          </StyledSlider>
          {isDesktop && infinite && (
            <NextButton
              aria-label="Show Next Reviews"
              onClick={handleNextSlide}
            >
              <NextSvg role="img" aria-label="Next Icon" />
            </NextButton>
          )}
        </Reviews>
      </Content>
      <Cta>
        <Button to={productUrl}>Shop Now</Button>
      </Cta>
    </Container>
  )
}
