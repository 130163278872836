import styled from "styled-components"
import { mq } from "styles"
import { $BgColor } from "typings/modules"

export const Container = styled.div<$BgColor>`
  background: ${({ $bgColor }) => $bgColor};
  position: relative;
  padding-top: 120px;
  ${mq.minWidth("md")} {
    padding-top: 180px;
  }
  width: 100%;
`

export const Content = styled.div`
  text-align: center;
  margin-bottom: 32px;
  color: ${({ theme }) => theme.dark.text?.primary?.opacityHex};
  *:last-child {
    margin-bottom: 0;
  }
  ${mq.minWidth("md")} {
    margin-bottom: 64px;
  }
`
