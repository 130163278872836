import React, {
  useState,
  useRef,
  useEffect,
  Dispatch,
  SetStateAction,
} from "react"
import "react-input-range/lib/css/index.css"
import { SanityProduct } from "typings/graphql"
import { ActiveProduct } from "./ReviewList"
import { Grid } from "components/UI/Grid/Grid"
import {
  Products,
  Product,
  Box,
  Ranges,
  RangeInput,
  ProductName,
  Img,
} from "./ReviewSelector.styles"
import { decodeProductId } from "utils/decodeId"
import { getImage } from "utils/imageUtils"

type Target = EventTarget & { scrollLeft: number }
type Props = {
  activeProduct: ActiveProduct
  setActiveProduct: Dispatch<SetStateAction<ActiveProduct>>
  products: SanityProduct[]
}

export const ReviewSelector = ({
  activeProduct,
  setActiveProduct,
  products,
}: Props) => {
  const [rangeValue, setRangeValue] = useState(0)
  const [showScrollBar, setShowScrollBar] = useState(true)
  const [rangeMax, setRangeMax] = useState(1)
  const [reviews, setReviews] = useState<SanityProduct[]>([])
  const productsRef = useRef<HTMLDivElement>(null)
  const updateActiveProduct = (product: SanityProduct) => {
    const id = product?.shopifyProduct.shopifyId
    const title = product?.shopifyProduct.title
    const handle = product?.shopifyProduct.handle
    const productId = decodeProductId(id)
    setActiveProduct({ title, productId, handle })
  }
  useEffect(() => {
    if (products) {
      updateActiveProduct(products[0])
      setReviews(products)
    }
  }, [products])
  useEffect(() => {
    if (productsRef && productsRef.current) {
      const handleScrollBar = () => {
        const scrollWidth = productsRef.current?.scrollWidth || 0
        const clientWidth = productsRef.current?.clientWidth || 0
        setShowScrollBar(scrollWidth > clientWidth)
        const windowWidth = window.innerWidth
        const width = scrollWidth || windowWidth + 1
        setRangeMax(width - windowWidth)
      }
      const timer = setTimeout(handleScrollBar)
      window.addEventListener("resize", handleScrollBar)
      return () => {
        clearTimeout(timer)
        window.removeEventListener("resize", handleScrollBar)
      }
    }
    return null
  }, [productsRef, products])
  return (
    <>
      <Products
        ref={productsRef}
        $showScrollBar={showScrollBar}
        onScroll={e => {
          setRangeValue((e.target as Target).scrollLeft)
        }}
      >
        {reviews?.map((review, i) => {
          const active = review.shopifyProduct?.handle === activeProduct.handle
          const reviewImage = getImage(review.reviewsItem?.reviewsImage)
          const altDescription =
            reviewImage?.description || `Image for ${review.title}`
          return (
            <Product
              aria-label="Update active product"
              key={`product-selector-${i}`}
              $showScrollBar={showScrollBar}
              $active={active}
              onClick={() => updateActiveProduct(review)}
            >
              <Box $active={active}>
                {review.reviewsItem?.reviewsImage && (
                  <Img image={reviewImage?.gatsbyImage} alt={altDescription} />
                )}
              </Box>
              <ProductName>{review.title}</ProductName>
            </Product>
          )
        })}
      </Products>
      {showScrollBar && (
        <Grid base="2 [8] 2" md="2 [12] 2" lg="4 [18] 4">
          <Ranges>
            <RangeInput
              step={1}
              minValue={0}
              maxValue={rangeMax}
              value={rangeValue}
              aria-label="Review Slider"
              onChange={value => {
                if (
                  productsRef &&
                  productsRef.current &&
                  typeof value === "number"
                ) {
                  productsRef.current.scrollLeft = value
                  setRangeValue(value)
                }
              }}
            />
          </Ranges>
        </Grid>
      )}
    </>
  )
}
