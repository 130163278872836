import React, { useState } from "react"
import { Maybe, YotpoProductReviews } from "typings/graphql"
import Checkmark from "assets/svg/checkmark.svg"
import { humanizeDate } from "utils/dateUtils"
import {
  ReviewInner,
  AuthorContainer,
  Author,
  VerifiedBadge,
  Meta,
  Date,
  Verified,
  Title,
  Content,
  Cta,
} from "./ReviewItem.styles"
import { ReviewStars } from "components/YotpoReviewList/ReviewStars"
import Modal from "components/Modal/Modal"

type Props = {
  review?: Maybe<YotpoProductReviews>
  reviewCount: number
}

type ReviewCardProps = {
  review?: Maybe<YotpoProductReviews>
  reviewCount: number
  isInModal?: boolean
  openReviewModal?: () => void
}

const ReviewCard = ({
  review,
  reviewCount,
  isInModal,
  openReviewModal,
}: ReviewCardProps) => {
  const authorsName = review?.user?.displayName ?? ""
  const reviewScore = review?.score ?? 0
  const reviewTitle = review?.title ?? ""
  const reviewContent = review?.content ?? ""
  const maxContentLength = 170
  const trimmedContent = reviewContent.substring(0, maxContentLength)
  const date = humanizeDate(review?.createdAt, false)
  const isTruncated = trimmedContent.length === maxContentLength && !isInModal
  const content = isTruncated ? trimmedContent : reviewContent
  const ellipsis = isTruncated && !isInModal ? "..." : ""
  const html = content + ellipsis
  return (
    <ReviewInner
      aria-label={"Open reviews modal"}
      onClick={openReviewModal}
      $isInsideModal={!!isInModal}
    >
      <ReviewStars starCount={reviewScore} reviewCount={reviewCount} />
      <AuthorContainer>
        <Author>{authorsName}</Author>
        <VerifiedBadge>
          <Checkmark role="img" aria-label="Verified Author Icon" />
        </VerifiedBadge>
      </AuthorContainer>
      <Meta>
        <Date>{date}</Date>
        <Verified>Verified Buyer</Verified>
      </Meta>
      <Title>
        <div dangerouslySetInnerHTML={{ __html: reviewTitle }} />
      </Title>
      <Content>
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </Content>
      {isTruncated && !isInModal && <Cta>Continue Reading</Cta>}
    </ReviewInner>
  )
}

export const ReviewItem = ({ review, reviewCount }: Props) => {
  const [reviewModal, setReviewModal] = useState(false)
  const openReviewModal = () => setReviewModal(true)
  const closeReviewModal = () => setReviewModal(false)
  return (
    <>
      <ReviewCard
        review={review}
        reviewCount={reviewCount}
        openReviewModal={openReviewModal}
      />
      {reviewModal && (
        <Modal onModalClose={closeReviewModal}>
          <ReviewCard review={review} reviewCount={reviewCount} isInModal />
        </Modal>
      )}
    </>
  )
}
