import React from "react"
import { SanityReviewList } from "typings/graphql"
import { ModeType } from "typings/modules"
import { ReviewList } from "./ReviewList"

export const SanityReviewListBlock = ({
  fields,
}: {
  fields: SanityReviewList
}) => (
  <ReviewList
    mode={fields.mode as ModeType}
    header={fields._rawHeader}
    bgColor={fields.bgColor?.opacityHex}
    products={fields.products}
  />
)

export default SanityReviewListBlock
