import styled, { css } from "styled-components"
import { mq, typography } from "styles"
import Slider from "react-slick"
import { NoStyleButton } from "components/UI/Button/NoStyleButton"
import { Link } from "gatsby"
import { $Mode } from "typings/modules"

export const Container = styled.div`
  color: ${({ theme }) => theme.dark.text?.primary?.opacityHex};
  padding: 64px 0;
  ${mq.minWidth("md")} {
    padding: 80px 0;
  }
  ${mq.minWidth("lg")} {
    padding: 96px 0;
  }
`

export const Content = styled.div`
  text-align: center;
  position: relative;
`

export const Review = styled.div`
  padding: 0 15px;
  height: 100%;
  outline: none;
`

export const Title = styled.h4<$Mode>`
  margin-bottom: 24px;
  color: ${({ $mode }) => `var(--text-headline-${$mode})`};
`

export const Pipe = styled.span`
  color: ${({ theme }) => theme.dark?.ui?.divider?.opacityHex};
`

export const Reviews = styled.div`
  position: relative;
`

export const StyledSlider = styled(Slider)`
  .slick-arrow {
    display: none !important;
  }
  .slick-track {
    display: grid !important;
    grid-auto-flow: column;
  }
  .slick-slide {
    & > div {
      height: 100%;
    }
  }
`
export const sharedButtonStyles = css`
  background-color: ${({ theme }) =>
    theme.dark.button?.primary?.fill?.opacityHex};
  width: 62px;
  height: 62px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 3;
  top: 50%;
  transform: translateY(-50%);
  transition: background-color 0.2s ease;
  display: none;
  ${mq.minWidth("md")} {
    display: flex;
  }
  svg {
    path {
      fill: ${({ theme }) => theme.dark.button?.primary?.text?.opacityHex};
    }
  }
  &:hover {
    background-color: ${({ theme }) =>
      theme.dark.button?.primary?.hoverFill?.opacityHex};
  }
`

export const PrevButton = styled(NoStyleButton)`
  ${sharedButtonStyles};
  left: 73px;
`

export const NextButton = styled(NoStyleButton)`
  ${sharedButtonStyles};
  right: 73px;
`

export const Cta = styled.div`
  text-align: center;
  padding-top: 32px;
  ${mq.minWidth("md")} {
    padding-top: 48px;
  }
  a {
    width: auto;
  }
`

export const StyledLink = styled(Link)`
  ${typography.body};
  color: var(--text-headline-dark);
  justify-self: center;
  margin-bottom: 28px;

  ${typography.defaultLink}

  ${mq.minWidth("md")} {
    margin-bottom: 48px;
  }
`

export const Meta = styled.div`
  ${typography.bodySmall};
  display: grid;
  grid-gap: 6px;
  grid-auto-flow: column;
  justify-content: center;
  margin: 13px 0 8px;
`
