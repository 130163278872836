import styled from "styled-components"
import { mq, typography } from "styles"
import InputRange from "react-input-range"
import { $Active } from "typings/modules"
import { NoStyleButton } from "components/UI/Button/NoStyleButton"
import { GatsbyImage } from "gatsby-plugin-image"

type $ShowScrollBar = { $showScrollBar: boolean }

export const Products = styled.div<$ShowScrollBar>`
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  justify-content: ${({ $showScrollBar }) =>
    $showScrollBar ? "normal" : "center"};
  position: relative;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  padding: 0 6.4vw;
  ${mq.minWidth("lg")} {
    padding: 0 9.4vw;
  }
`

export const Product = styled(NoStyleButton)<$Active & $ShowScrollBar>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 180px;
  opacity: ${({ $active }) => ($active ? 1 : 0.5)};
  transition: opacity 0.25s ease;
  &:hover {
    cursor: pointer;
    opacity: 1;
  }
  margin-right: 24px;
  ${mq.minWidth("md")} {
    margin-right: 48px;
  }
  ${mq.minWidth("lg")} {
    margin-right: 60px;
  }
  &:last-of-type {
    margin-right: 0;
    display: flex;
    align-items: flex-start;
    width: ${({ $showScrollBar }) =>
      $showScrollBar ? "calc(180px + 6.4vw)" : "180px"};
    ${mq.minWidth("md")} {
      width: ${({ $showScrollBar }) =>
        $showScrollBar ? "calc(180px + 9.4vw)" : "180px"};
    }
  }
`

export const Box = styled.div<$Active>`
  border: ${({ $active, theme }) =>
    $active ? `1px solid ${theme.dark.ui?.divider?.opacityHex}` : `none`};
  width: 180px;
  height: 150px;
  div {
    height: 100%;
    width: 100%;
  }
`

export const Ranges = styled.div`
  margin-top: 32px;
  .input-range__label {
    display: none;
  }
  .input-range__slider-container,
  .input-range__slider,
  .input-range__track {
    transition: none;
  }
  .input-range__slider {
    height: 4px;
    width: 12vw;
    background: var(--slider-bar-active);
    transform: translate(-50%, -100%);
    border-radius: 0;
    margin-left: 0;
    margin-top: 0;
    border: 0;
    z-index: 2;
    position: relative;
    &:active {
      transform: translate(-50%, -100%);
    }
    &:hover {
      cursor: pointer;
    }
    &:before {
      content: "";
      height: 16px;
      background: transparent;
      position: absolute;
      top: -16px;
      left: 0;
      width: 100%;
    }
    &:after {
      content: "";
      height: 16px;
      background: transparent;
      position: absolute;
      top: 4px;
      left: 0;
      width: 100%;
    }
  }
  .input-range__track {
    height: 4px;
    background: var(--slider-bar-inactive);
    border-radius: 0;
    position: relative;
    &:before {
      content: "";
      height: 4px;
      background: var(--slider-bar-inactive);
      display: block;
      position: absolute;
      top: 0;
      left: -6vw;
      width: 6vw;
    }
    &:after {
      content: "";
      height: 4px;
      background: var(--slider-bar-inactive);
      display: block;
      position: absolute;
      top: 0;
      right: -6vw;
      width: 6vw;
      z-index: 0;
    }
  }
`
export const RangeInput = styled(InputRange)`
  width: 100%;
`

export const ProductName = styled.p`
  ${typography.body};
  color: ${({ theme }) => theme.dark.text?.primary?.opacityHex};
  margin-top: 8px;
  min-width: 180px;
  text-align: center;
`

export const Img = styled(GatsbyImage)`
  ${mq.minWidth("md")} {
    width: 360px;
    height: 360px;
  }
`
